/* html {
    overflow-y: scroll;
} */

/* body {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

main {
  flex: 1;
  min-height: 0;
} */

body {
  margin: 0;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(192, 192, 192);
}
.scroller {
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-color: rgb(192, 192, 192);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-corner {
  background: transparent;
}

/* *::-webkit-scrollbar-track { */
  /* box-shadow: inset 0 0 2px rgba(112, 112, 112, 0.3); */
/* } */

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: darkgrey;
  /* outline: 1px solid slategrey; */
}
.overflow-custom-div {
  overflow: hidden;
}

.overflow-custom-div:hover {
  overflow-y: scroll;
}

.overflow-custom-div-x {
  overflow: hidden;
}

.overflow-custom-div-x:hover {
  overflow-x: auto;
  overflow-y: auto;
}

.custom-outline-label {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: gray;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}
.custom-outline-input {
  width: 100%;
  font-size: 1rem;
  outline: none;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  padding: 1rem 0.7rem;
  color: gray;
  transition: 0.1s ease-out;
}
.custom-outline-input:focus {
  border-color: #6200ee;
}
.custom-outline-input:focus + label {
  color: #6200ee;
  top: 0;
  transform: translateY(-50%) scale(0.9);
}
.custom-outline-input:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.custom-input-container .MuiOutlinedInput-input {
  padding: 10px !important;
}

.MuiPaginationItem-textPrimary.Mui-selected {
  color: #fff !important;
  background-color: #7c3aed !important;
}

.customSelect .css-6j8wv5-Input {
  margin: 4px !important;
}
.customSelect .css-1pndypt-Input {
  margin: 4px !important;
}

textarea {
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

.windmilInput {
  border-width: 2px;
}
.windmilInput:hover {
  border-color: #6200ee;
}

.active-link{
  background-color: #FFFFFF40;
}

.slickgrid-container , .slickgrid-container .slick-row ,.slickgrid-container .slick-header-columns .slick-header-column.slick-state-default{
  font-family: "Inter", ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }
.slickgrid-container .slick-header-columns .slick-header-column.slick-state-default{
  font-size: 16px !important;
  font-weight: 500 !important;
}
.slickgrid-container .grid-canvas .slick-cell{
  font-size: 14px !important;

}

.text-truncate {
  display: inline-block;
  max-width: 64ch; /* Limits text to ~64 characters */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
